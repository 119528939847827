<template>
  <div class="swiper">
    <div class="swiper-wrapper">
      <slot />
    </div>

    <div class="swiper-button-prev">
      <span class="rotate-180">
        <Next />
      </span>
    </div>
    <div class="swiper-button-next">
      <span>
        <Next />
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import Swiper from 'swiper'
import { Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'

import Next from '~/assets/svg/next.svg'

onMounted(() => {
  const swiper = new Swiper('.swiper', {
    // configure Swiper to use modules
    modules: [Navigation],
    spaceBetween: 32,
    slidesPerView: 1,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      768: {
        slidesPerView: 3,
      },
    },
  })
})
</script>

<style lang="postcss">
:root {
    --swiper-theme-color: white;
    --swiper-navigation-size: 34px;
    /* 62.5px is the height below the image */
    --swiper-navigation-top-offset: calc(50% - 62.5px / 2);
}

.swiper-button-next,
.swiper-button-prev {
    &::after {
        content: none;
    }
}

.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
    opacity: 0;
}

.swiper-button-next {
    /* 32px is the padding size, 34px is the nav size */
    right: calc((50% + 32px - 50vw - 34px) / 2);
}

.swiper-button-prev {
    left: calc((50% + 32px - 50vw - 34px) / 2);
}
</style>
